.taskmaster {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.header {
  height: 0px;
  width: 100%;
  background-color: darkgray;
  
}
.header-body {
  line-height: 50px;
}

.header-team-members {
  text-align: center;
}

.sideNav-container {
  width: 10%;
  min-width: 160px;
  background-color:#16141c;
  padding: 5px;
  border-right: solid grey 1px;
}

.taskmaster-logo {
  padding-top: 30px;
  margin-bottom: 70px;
  color: aliceblue;
}

.sideNav {
  height: 100vh;
  background-color:#16141c;
  padding: 0px 20px 0px 20px;
  text-align: center; 
  justify-content: center;
}

.nav-item {
  text-align: center;
  color: grey;
}
.nav-item:hover {
  color: white;
  text-decoration: none;
}

.nav-heading {
  margin-bottom: 40px;
}

.main {
  width: 90%;
  height: 100%;  
} 

.dashboard-main {
  overflow-x: auto;
  overflow-y: scroll;
  white-space: nowrap;
  background-color: grey;
}
.dashboard-header {
  height: auto;
  background-color: lightgray;
  background-color:#16141c;
  border-bottom: solid grey 1px;
  padding: 20px 30px 20px 30px;
  position: relative;
}

.header-dashAddNav {
  display: inline-block;
  margin-right: 200px;
}
.dash-input {
  background-color: #16141c;
  color: white;
}

.header-team {
  display: inline-block;
  position: absolute;
  top: 20px;
  min-width: auto;
  min-height: max-content;
  text-align: center;
}
.teamTitle {
  display: inline-block;
  margin-right: 10px;
  color: whitesmoke;
  font-size: 18px;
}
.invite-form {
  display: inline-block;
  right: 0px;
}
.invite-input{
  background-color: #16141c;
  color: white;
}
.addedUsers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.user {
  margin-left: 5px;
}
.invite-form-container {
  width: 320px;
}

.dash-nav-add {
  width: 240px;
}

.team-add-container {
  background-color: teal;
}

.dashboard-subHeader {
  background-color: #272727;
  height: auto;
  padding: 10px 30px 20px 30px;
  position: relative;
  color: white;
}

.dash-team {
  height: 80px;
}
.dash-spacer {
  width: 50%;
  float: left;
}
.dash-teamIcons {
  width: 50%;
  float:right;
  background-color: blue;
}

.dash-delete {
  position: absolute;
  top: 20px;
  right: 30px;
}

.project-column-wrapper {
  background-color: #16141c;
  background-color: #272727;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  align-items: flex-start;
  align-content: flex-start;
}

.project-column {
  min-width: 350px;
  /* min-height: 300px; */
  height: auto;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  background-color: #16141c;
}

.column-header {
  width: 100%;
  float: right;
  left: 0px;
  margin-bottom: 8px;
}
.colDel {
  float: right;
}


.card {
  margin-top: 10px;
  min-width: 300px;
  width: auto;
  padding: 8px;
  background-color: grey;
  background-color: #272727;
  border: grey solid 1px;
}

.card-utility-header {
  width: auto;
  display: flex;
  flex-direction: row-reverse;
  float: right;
  text-align: center;
}

.card-header {
  width: 100%;
  padding: 10px;
}
.card-body {
  padding: 0px 10px 10px 10px;
}

.textArea {
  width: 100%;
}
.cardDelBtn {
  background-color: #272727;
  color: white;
}

.textAreaCardTitle {
  background-color: #272727;
  color: white;
}

.textAreaDesc {
  background-color: #272727;
  color: white;
}

.dueDate-cal-container {
  margin-top: 6px;
  margin-bottom: 8px;

}
.dueDate-cal {
  margin-bottom: 8px;
  background-color:#272727;
  color: white;
}


.mytasks-header {
  text-align: center;
  line-height: 200px;
  height: 200px;
  background-color: #16141c;
  font-size: 48px;
  color: grey;
}
.mytasks-container {
  background-color: #272727;
  min-height: 100vh;
  height: auto;
  padding: 20px 60px 20px 60px;
}

.mytasks-dash {
  background-color: #272727;
  padding: 20px;
  
}
.mytasks-dash-title {
  color: whitesmoke;
  margin-bottom: 20px;
  padding: 0px;
  border-bottom: solid black 2px;
}

.mytasks-column {
  background-color: #272727;
  padding: 10px 20px 10px 20px;
  margin-bottom: 10px;;
}
.mytasks-column-title {
  color: whitesmoke;
  border-bottom: solid black 2px;

}
.mytasks-card {
  background-color: #16141c;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 20px 30px 20px 30px;
  position: relative;
  border-radius: 10px;
}

.cardTitle {
  display: inline-block;
  padding: 5px;
  width: 100px;
  margin-right: 30px;
  color: whitesmoke;
}
.cardDesc {
  display: inline-block;
  padding: 5px;
  width: 300px;
  height: auto;
  margin-right: 30px;
  color: whitesmoke;
}
.cardDueDate {
  display: inline-block;
  padding: 5px;
  /* float: right; */
  width: auto;
  color: whitesmoke;
  position: absolute;
  top: 20px;
  right: 30px;
}
.registerPage {
  display: flex;
  flex-direction: row;

}

.container-left {
  height: 100vh;
  width: 50%;
  background-image: linear-gradient(#232526,#414345);
  display: flex;
  flex-direction: initial;
  justify-content: center;
}

.title-container {
  width: 500px;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  background-color: #272727;
  padding:40px;
  color: white;
  border-radius: 20px;
  border: solid grey 1px;
}
.container-right {
  height: 100vh;
  width: 50%;
  background-color: grey;
  background-image: linear-gradient(#232526,#414345);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.register-card {
  max-width: 500px;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  background-color: #272727;
  color: white;
  border-radius: 20px;
  padding: 16px;
  border: solid grey 1px;
}
.register-input {
  background-color: #272727;
  color: grey;
}

.title-mobile {
  display: none;
}

@media only screen and (max-width: 800px) {
  .container-left {
    display: none;
  }
  .container-right {
    width: 100%;
  }
  .title-mobile {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    text-align: center;
    color: white;
  }
}

.footer-login {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.loginBtn {
  margin-left: 20px;
}

.loginPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(#232526,#414345);
}

.login-card {
  margin-top: 500px;
  width: 500px;
  margin: auto;
  background-color: #272727;
  color: white;
  border-radius: 20px;
  padding: 16px;
  border: solid grey 1px;
}

.title-header {
  text-align: center;
}
.login-input {
  background-color: #272727;
  color: grey;
}

.footer-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media only screen and (max-width: 800px) {
  .login-card {
    width: auto;
  }
}




